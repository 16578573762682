import {StrUtil} from '@front-libs/helpers';
import {Grid, SxProps, Theme, Dialog} from '@mui/material';
import React, {useMemo} from 'react';
import {DialogButton} from './DialogButton';
import {DialogContent} from '@front-libs/ui';
import {MEIDAI_HIT_4888_UNIT_RELATION_FLAG} from '@constants/constants';
import {UnacceptedStatus} from '@Apps/BaseSharedMenu/types';

type Props = {
  open: boolean;
  status: UnacceptedStatus;
  type: 'rent' | 'return';
  buttonLabel?: string;
  onClickButton: () => void;
  additionalInfo?: ErrorAdditionalInfo;
};

export type ErrorAdditionalInfo =
  | {
      managementId?: string;
      scannedBarcode?: string;
    }
  | undefined;

/**
 * RentalErrorDialog - 対象スキャン時のエラーダイアログ コンポーネント (貸出対象 が 貸出/返却できない状況の時に表示する)
 *
 * @param {boolean} open - ダイアログの表示・非表示 フラグ
 * @param {UnacceptedStatus} status - 貸出できない理由 を指定します
 * @param {'rent' | 'return'} type - 貸出(rent) または 返却(return) を指定 します
 * @param {string} [buttonLabel='前の画面に戻る'] - ダイアログボタンのラベルを指定します
 * @param {() => void} onClickButton - ボタンクリック時のアクションを指定します
 * @param {string} scannedBarcode - 追加情報表示するためのオプションを指定します (読取られたバーコードの値 / エラー対象機器の管理番号)
 * @returns rendered RentalErrorDialog component.
 */
export const RentalErrorDialog = ({
  open,
  status,
  type,
  buttonLabel = '前の画面に戻る',
  onClickButton,
  additionalInfo,
}: Props) => {
  const message = useMemo(() => {
    switch (status) {
      case 'working':
        return 'この医療機器は貸出中のため貸出できません。';
      case 'uninspected':
        return 'この医療機器は点検が未完了のため貸出できません。';
      case 'repairing':
        return 'この医療機器は修理中のため貸出できません。';
      case 'disabled':
        return 'この医療機器は廃棄済みのため貸出できません。';
      case 'overdue_inspection':
        return 'この医療機器は点検予定日が近いので貸出できません。';
      case 'target_not_found':
        return `バーコードに該当する${type === 'rent' ? '貸出可能' : '返却可能'}な機器が見つかりません。`;
      default:
        return `この医療機器は${type === 'rent' ? '貸出' : '返却'}できません。`;
    }
  }, [status, type]);

  if (!MEIDAI_HIT_4888_UNIT_RELATION_FLAG) {
    return (
      <Dialog open={!!open} maxWidth="xl" data-testid={'rental-error-dialog'}>
        <DialogContent sx={contentStyle}>
          <Grid container direction="column" style={{height: '100%'}} alignContent="center">
            <Grid
              item
              style={{height: '60%', display: 'flex', alignItems: 'center'}}
              data-testid={'rental-error-dialog-content'}>
              {StrUtil.nl2br(`${message}\n別の医療機器を読み取り直してください。`)}
            </Grid>
            <Grid item sx={{height: '40%', display: 'flex', alignItems: 'center'}}>
              <DialogButton onClick={onClickButton}>{buttonLabel}</DialogButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  const additionalMessage: string = useMemo(() => {
    if (additionalInfo) {
      if (additionalInfo.scannedBarcode && additionalInfo?.scannedBarcode.length > 0) {
        return `(バーコード)\n${additionalInfo.scannedBarcode}`;
      }
      if (additionalInfo.managementId && additionalInfo?.managementId.length > 0) {
        return `(管理番号)\n${additionalInfo.managementId}`;
      }
    }
    return '';
  }, [additionalInfo]);

  return (
    <Dialog open={open} maxWidth="xl" data-testid={'rental-error-dialog'}>
      <DialogContent sx={contentStyle}>
        <Grid container direction="column" style={{height: '100%'}} alignContent="center">
          <Grid
            item
            style={{height: '60%', display: 'flex', alignItems: 'center'}}
            data-testid={'rental-error-dialog-content'}>
            {additionalMessage
              ? StrUtil.nl2br(`${message}\n\n${additionalMessage}\n\n別の医療機器を読み取り直してください。`)
              : StrUtil.nl2br(`${message}\n別の医療機器を読み取り直してください。`)}
          </Grid>
          <Grid item sx={{height: '40%', display: 'flex', alignItems: 'center'}}>
            <DialogButton onClick={onClickButton}>{buttonLabel}</DialogButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const contentStyle: SxProps<Theme> = {
  width: '60vw',
  height: '70vh',
  fontWeight: 700,
  fontSize: '32px',
  textAlign: 'center',
};
