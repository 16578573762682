import {CategoryFormatter} from '@modules/categories/helpers';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {Box} from '@mui/material';
import _ from 'lodash';
import React, {useMemo} from 'react';
import {BaseSharedListTable, BaseSharedListTableColumn, tableTitleStyle} from '../BaseSharedListTable';
import {CancelButton} from '../CancelButton';
import {RentableProduct} from '@modules/rentals/api';
import {MEIDAI_HIT_4888_UNIT_RELATION_FLAG} from '@constants/constants';
import {RentalTargetHospitalProducts} from '@Apps/BaseSharedMenu/RentDevices/types';

type RowData = {
  managementId: string;
  rentRoom: string | undefined;
  narrowCategoryName: string;
  displayName: string;
  cancelButton: JSX.Element | null;
};

const columns: BaseSharedListTableColumn<RowData>[] = [
  {id: 'managementId', label: '管理番号', minWidth: '140px'},
  {id: 'rentRoom', label: '貸出場所', minWidth: '80px'},
  {id: 'narrowCategoryName', label: '小分類', minWidth: '80px'},
  {id: 'displayName', label: '機種名', minWidth: '120px'},
  {id: 'cancelButton', label: '', minWidth: 'fit-content', sx: {width: 'fix-content'}},
];

export type TableProps = {
  rentingHospitalProducts: HospitalProductIndex[];
  rentalTargetHospitalProducts: RentalTargetHospitalProducts;
  rentRoom: string;
  onClickCancel: (rowIndex: number) => void;
};

export const RentProductTable = ({
  rentingHospitalProducts /* 現行実装の貸出対象機器リスト */,
  rentalTargetHospitalProducts /* 貸出対象機器リスト(親子関係対応済) */,
  rentRoom,
  onClickCancel,
}: TableProps) => {
  let tableRows: RowData[];
  if (MEIDAI_HIT_4888_UNIT_RELATION_FLAG) {
    tableRows = rentalTargetHospitalProducts.map((item, index) => ({
      managementId: item.managementId ?? '',
      narrowCategoryName: item.narrowCategoryName,
      displayName: item.displayName ?? '',
      rentRoom: rentRoom ?? '',
      cancelButton: item.managementId ? (
        <CancelButton
          onClickCancel={() => {
            onClickCancel(index);
          }}
        />
      ) : null,
    }));
  } else {
    tableRows = rentingHospitalProducts.map((item, index) => ({
      managementId: item.managementId ?? '',
      narrowCategoryName: CategoryFormatter.getNarrowCategory(item.categories ?? [])?.name ?? '',
      displayName: item.displayName ?? '',
      rentRoom: rentRoom ?? '',
      cancelButton: item.managementId ? (
        <CancelButton
          onClickCancel={() => {
            onClickCancel(index);
          }}
        />
      ) : null,
    }));
  }
  return (
    <>
      <Box sx={tableTitleStyle}>貸出する医療機器リスト</Box>
      <BaseSharedListTable<RowData> tableRows={tableRows} columns={columns} />
    </>
  );
};
