import {Box, Grid, SxProps, Theme, styled} from '@mui/material';
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {Header} from '@Apps/BaseSharedMenu/Header';
import {Footer} from '@Apps/BaseSharedMenu/Footer';
import camera from '../../../assets/camera.png';
import barcode2 from '../../../assets/barcode2.png';
import {ActivateCameraButton} from '../../../ActivateCameraButton';
import {contentFixedStyle} from '@components/templates/RentalTemplate';
import {useSetAtom} from 'jotai';
import {scannedProductAtom, scannedReturnTargetHospitalProductsAtom} from '../states';
import {defaultBarcodeScannerTypeAtom} from '@Apps/BaseSharedMenu/states';

/**
 * 返却の「カメラで読み取る」ページ (案内ページ)
 * @todo BaseSharedMenu/RentDevices/RentByCamera/ConfirmScan/index.tsx とほぼ同じなので共通化したい
 */
export const ConfirmScanReturnDevice = () => {
  const navigate = useNavigate();
  const setRentHospitalProducts = useSetAtom(scannedProductAtom);
  const setReturnTargetHospitalProducts = useSetAtom(scannedReturnTargetHospitalProductsAtom);
  const setDefaultBarcodeScannerType = useSetAtom(defaultBarcodeScannerTypeAtom);

  const handleOnClick = useCallback(() => {
    setRentHospitalProducts([]);
    setReturnTargetHospitalProducts([]);
    navigate('/shared/return/product/camera/scan');
  }, [navigate, setRentHospitalProducts, setReturnTargetHospitalProducts]);

  const handleClickSwitchBarcodeReader = useCallback(() => {
    setDefaultBarcodeScannerType('barcode_reader');
    navigate('/shared/return/product/barcode_reader');
  }, [navigate, setDefaultBarcodeScannerType]);

  return (
    <>
      <Header title={'返却'} />
      <Box sx={contentFixedStyle}>
        <Box sx={gridContainerStyle}>
          <Box sx={cameraImgContainerStyle}>
            <CameraImg src={camera} alt="カメラ読み取り" />
          </Box>
          <Box sx={rightSideContainerStyle}>
            <BarcodeImg2Style src={barcode2} alt="バーコードの場所" />
            <ActivateCameraButton label={'読み取り開始'} onClick={handleOnClick} />
          </Box>
        </Box>
      </Box>
      <Footer
        text={'返却機器のバーコードを\nカメラで読み取ります'}
        nextButtonLabel={'リーダーで読み取る'}
        onClickNextButton={handleClickSwitchBarcodeReader}
      />
    </>
  );
};

const CameraImg = styled('img')({
  maxWidth: '80%',
});

const BarcodeImg2Style = styled('img')(({theme}) => ({
  maxWidth: '80%',
  marginBottom: '32px',
  [theme.breakpoints.up('tabletH')]: {
    marginBottom: '48px',
  },
}));

const cameraImgContainerStyle: SxProps<Theme> = {
  background: '#FFFFFF',
  borderRadius: '10px',
  padding: '20px 80px',
  display: 'flex',
  justifyContent: 'center',
};

const rightSideContainerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const gridContainerStyle: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: '40px',
  ml: 10,
  mr: 10,
};
