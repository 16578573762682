import {HospitalProductIndex} from '@modules/hospital_products/types';
import {Box} from '@mui/material';
import React from 'react';
import {
  tableTitleStyle,
  BaseSharedListTable,
  BaseSharedListTableColumn,
} from '@Apps/BaseSharedMenu/BaseSharedListTable';
import {CancelButton} from '@Apps/BaseSharedMenu/CancelButton';
import {RentalTargetHospitalProducts} from '@Apps/BaseSharedMenu/RentDevices/types';
import {MEIDAI_HIT_4888_UNIT_RELATION_FLAG} from '@constants/constants';

type RowData = {
  managementId: string;
  cancelButton: JSX.Element | null;
};

const columns: BaseSharedListTableColumn<RowData>[] = [
  {id: 'managementId', label: '管理番号', minWidth: '140px'},
  {id: 'cancelButton', label: '', minWidth: 'fit-content', sx: {width: 'fix-content'}},
];

export type TableProps = {
  rentingHospitalProducts: HospitalProductIndex[];
  rentalTargetHospitalProducts: RentalTargetHospitalProducts;
  onClickCancel: (rowIndex: number) => void;
};

export const ScannedProductTable = ({
  rentingHospitalProducts /* 現行実装の貸出対象機器リスト */,
  rentalTargetHospitalProducts /* 貸出対象機器リスト(親子関係対応済) */,
  onClickCancel,
}: TableProps) => {
  let tableRows: RowData[];
  if (MEIDAI_HIT_4888_UNIT_RELATION_FLAG) {
    tableRows = rentalTargetHospitalProducts.map((item, index) => ({
      managementId: item.managementId ?? '',
      cancelButton: item.managementId ? (
        <CancelButton
          onClickCancel={() => {
            onClickCancel(index);
          }}
        />
      ) : null,
    }));
  } else {
    tableRows = rentingHospitalProducts.map((item, index) => ({
      managementId: item.managementId ?? '',
      cancelButton: item.managementId ? (
        <CancelButton
          onClickCancel={() => {
            onClickCancel(index);
          }}
        />
      ) : null,
    }));
  }
  return (
    <>
      <Box sx={tableTitleStyle}>読み取った機器リスト</Box>
      <BaseSharedListTable<RowData> tableRows={tableRows} columns={columns} />
    </>
  );
};
