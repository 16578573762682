import {QueryClient} from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // フォーカスがウィンドウに戻るたびに再取得をおこなわない用にfalseを設定
      refetchOnWindowFocus: false,
    },
  },
});

export interface Pageable {
  page?: number;
  size?: number;
  sort?: string;
}

export const getMeKey = 'getMe';
export const getMyResourceKey = 'getMyResourceKey';
export const getUserRolesKey = 'getUserRolesKey';

export const getHospitalUsersKey = 'getHospitalUsers';

export const getCategoriesKey = 'getCategories';

export const getProductKey = 'getProduct';
export const getProductsKey = 'getProducts';

export const getHospitalProductsKey = 'getHospitalProducts';
export const getHospitalProductLogsKey = 'getHospitalProductLogs';
export const getHospitalProductKey = 'getHospitalProduct';
export const getHospitalProductLogKey = 'getHospitalProductLog';
export const getHospitalProductCostTrendsByCostCategoryKey = 'getHospitalProductCostTrendsByCostCategoryKey';
export const getHospitalProductCostTrendsByRootCategoryKey = 'getHospitalProductCostTrendsByRootCategoryKey';
export const getHospitalProductCostTrendsByNarrowCategoryKey = 'getHospitalProductCostTrendsByNarrowCategoryKey';
export const getWholeProductKey = 'getWholeProduct';

export const getRentalsKey = 'getRentals';
export const getFaultRepairsKey = 'getFaultRepairsKey';
export const getFaultRepairKey = 'getFaultRepairKey';
export const getFaultRepairRatioKey = 'getFaultRepairRatioKey';
export const getFaultRepairsStatusesKey = 'getFaultRepairsStatusesKey';
export const getFaultRepairLogsKey = 'getFaultRepairLogsKey';
export const getHospitalProductRentalHistoiesKey = 'getHospitalProductRentalHistoiesKey';
export const getHospitalProductFaultRepairHistoiesKey = 'getHospitalProductFaultRepairHistoiesKey';
export const getHospitalProductFilesKey = 'getHospitalProductFilesKey';
export const getHospitalKey = 'getHospital';

export const getInspectionsKey = 'getInspections';
export const getInspectionCountsKey = 'getInspectionCounts';
export const getInspectionKey = 'getInspection';
export const getInspectionListKey = 'getInspectionList';

export const getInspectionHospitalProductsKey = 'getInspectionHospitalProducts';
export const getHospitalProductInspectionPlansKey = 'getHospitalProductInspectionPlans';
export const getHospitalProductInspectionPlanCountsKey = 'getHospitalProductInspectionPlanCounts';

export const getInspectionResultKey = 'getInspectionResult';
export const getInspectionResultsKey = 'getInspectionResults';
export const getNextInspectionResultKey = 'getNextInspectionResult';
export const getInspectionResultsCountsKey = 'getInspectionResultsCounts';
export const getInspectionResultFilesKey = 'getInspectionResultFiles';

export const getInspectionPlansKey = 'getInspectionPlans';

export const getInspectionPeriodsKey = 'getInspectionPeriods';
export const getInspectionPeriodProductsByAssignedKey = 'getInspectionPeriodProductsByAssigned';

export const getHospitalWardsKey = 'getHospitalWards';
export const getHospitalRoomsKey = 'getHospitalRooms';
export const getHospitalRoomKey = 'getHospitalRoom';

export const getHospitalWardLayoutSettingsKey = 'getHospitalWardLayoutSettings';
export const getHospitalRoomLayoutSettingsKey = 'getHospitalRoomLayoutSettings';

export const getFaultRepairPropertyRequirementsKey = 'FaultRepairPropertyRequirements';

export const getNotificationSettingsKey = 'getNotificationSettings';

export const getChannelKey = 'getChannel';
export const getRentalCapabilitySummaryKey = 'getRentalCapabilitySummary';
export const getRentalCapabilityNarrowCategoryKey = 'getRentalCapabilityNarrowCategory';
export const getDashboardRentalHistoryKey = 'getDashboardRentalHistory';
export const getDashboardMonthlyInspectionKey = 'getDashboardMonthlyInspection';
export const getProductPinnedCommentsKey = 'getProductPinnedCommentsKey';

export const getUncompletedInspectionsByCategoryKey = 'getUncompletedInspectionsByCategoryKey';
export const getRepairPinnedCommentsKey = 'getRepairPinnedCommentsKey';
export const getUncompletedInspectionsByNarrowCategoryKey = 'getUncompletedInspectionsByNarrowCategoryKey';
export const getUncompletedInspectionsByRootCategoryKey = 'getUncompletedInspectionsByRootCategoryKey';

export const getTrainingScheduleKey = 'getTrainingSchedule';
export const getTrainingReportsKey = 'getTrainingReports';

export const getTrainingScheduleMaterialsKey = 'getTrainingScheduleMaterials';
export const getTrainingScheduleTraineeListsKey = 'getTrainingScheduleTraineeLists';

export const getHospitalProductPmdaFilesKey = 'getHospitalProductPmdaFiles';

export const getHospitalDeviceLendersKey = 'getHospitalDeviceLenders';

export const getFaultReceptionsKey = 'getFaultReceptionsKey';
export const getFaultReceptionKey = 'getFaultReceptionKey';

export const getFaultReceptionLogsKey = 'getFaultReceptionLogsKey';
export const getHospitalProductNoteSettingsKey = 'getHospitalProductNoteSettingsKey';

export const getInspectionSettingKey = 'getInspectionPeriods';

export const getWholeProductsKey = 'getWholeProductsKey';

export const getHospitalDepartmentsKey = 'getHospitalDepartments';

export const getWholeProductPartsKey = 'getWholeProductParts';

export const getUnitRelationsKey = 'getUnitRelationsKey';

export const getWholeProductPmdaFilesKey = 'getWholeProductPmdaFilesKey';

export const getReturnWaitingReceptionsKey = 'getReturnWaitingReceptionsKey';
